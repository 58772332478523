import React, { useEffect, useState } from "react";
import PageBg from "./pageBg";
import styles from "./login.module.css";
import { Form, Input } from "antd-mobile";
import useStorage from "@/utils/storage";
import { useNavigate } from "react-router-dom";
import { PlugMobileProvider } from "@funded-labs/plug-mobile-sdk";
import { SpinLoading } from "antd-mobile";
const canisterId = "af7a628b750917a56ab5648a10040df4";
const whitelist = [canisterId];
const host = "https://a4gq6-oaaaa-aaaab-qaa4q-cai.raw.icp0.io";
const intervalId1 = null;
const intervalId2 = null;
// Callback to print sessionData
const onConnectionUpdate = () => {
  console.log(window.ic.plug.sessionManager.sessionData);
};
function Home() {
  const store = useStorage();
  const navigate = useNavigate();
  const [provider, setProvider] = useState([]);
  const [form] = Form.useForm();
  const [principalId, setPrincipalId] = useState(
    store.get("Mindra-generateLogin-principalId") || ""
  );
  const [isProviderInitialized, setIsProviderInitialized] = useState(false);

  useEffect(() => {
    if (principalId) {
      form.setFieldValue("principalId", principalId);
      store.set("Mindra-generateLogin-principalId", principalId);
      setTimeout(() => {
        navigate({ pathname: "/generateIndex" });
      }, 1000);
    }
  }, [principalId]);

  useEffect(() => {
    const checkPairingAndContinue = async () => {
      if (!isProviderInitialized) {
        return;
      }
      console.log("provider.isPaired()", provider.isPaired());

      if (provider && provider.isPaired()) {
        let walletData = provider.walletConnectSession;
        let principal_id = "";
        if (walletData?.namespaces?.icp?.accounts.length > 0) {
          let dataArray = walletData?.namespaces?.icp?.accounts[0].split(":");
          principal_id = dataArray[dataArray.length - 1];
          setPrincipalId(principal_id);
        }
      }
    };
    const intervalId = setInterval(checkPairingAndContinue, 1000); // Adjust the interval as needed
    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [provider]);
  const connectWallet = () => {
    console.log("isProviderInitialized", isProviderInitialized);
    if (!isProviderInitialized) {
      return;
    }
    console.log("provider", provider);
    if (provider && !provider.isPaired()) {
      if (!provider.provider) {
        return;
      }
      if (provider.isWallectConnectPaired) {
        return;
      }
      if (!provider.wcPairUri) {
        return;
      }
      if (!provider.connectionPromise) {
        return;
      }
      const url = `https://plugwallet.ooo/wc?uri=${provider.wcPairUri}`;
      window.location = url;
      console.log("Redirecting to WalletConnect pairing URL");
    }
    const intervalId = setInterval(() => {
      clearInterval(intervalId);
      // Trigger connectionPromise logic here
      provider
        .connectionPromise()
        .then((session) => {
          provider.isWallectConnectPaired = true;
          provider.walletConnectSession = session;
          console.log("WalletConnect session established");
        })
        .catch((error) => {
          console.log(`Connection error: ${error}`);
        });
    }, 1000); // Check every 1 second
  };
  const initializeProvider = async () => {
    if (isProviderInitialized) {
      return;
    }
    const isMobile = PlugMobileProvider.isMobileBrowser();
    console.log(`Is mobile: ${isMobile}`);

    var newProvider = new PlugMobileProvider({
      debug: true,
      walletConnectProjectId: "af7a628b750917a56ab5648a10040df4",
      window: window,
    });
    console.log(`Provider initialized: ${newProvider}`);

    try {
      await newProvider.initialize();
      console.log("Provider initialized successfully");
      setIsProviderInitialized(true); // Set provider initialization flag
      setProvider(newProvider);
    } catch (error) {
      console.log(`Provider initialization error: ${error}`);
    }
  };
  const init = async () => {
    // if (window.location.host == "localhost:3000") {
    //   const publicKey = await window.ic.plug.requestConnect({
    //     whitelist,
    //     host,
    //     onConnectionUpdate,
    //     timeout: 50000,
    //   });
    //   console.log("--", window.ic.plug.principalId);
    //   setPrincipalId(window.ic.plug.principalId);
    // } else {
    initializeProvider();
  };
  useEffect(() => {
    init();
  }, []);

  const onFinish = async (values) => {
    store.set("Mindra-generateLogin-principalId", values.principalId);
    navigate({ pathname: "/generateIndex" });
  };
  const validateMessages = {
    required: "${name} is required",
  };
  return (
    <PageBg>
      <div className={styles.GenerateIndex}>
        <div className={styles.title}>Type Your Principal ID</div>
        <div className={styles.desc}>
          The plug app will automatically open. Please copy your Principal ID
          into the input box
        </div>
        <div className={styles.formContainer}>
          <Form
            form={form}
            requiredMarkStyle="none"
            onFinish={onFinish}
            validateMessages={validateMessages}>
            <Form.Item
              name="principalId"
              label="Principal ID"
              rules={[{ required: true }]}>
              <Input placeholder="Type your principal id" />
            </Form.Item>
          </Form>
        </div>
        {!isProviderInitialized && (
          <div className={styles.info}>
            Sdk loading. you can also input principal id manually to continue.{" "}
          </div>
        )}
        <div className={styles.buttonContainer}>
          {isProviderInitialized && (
            <div className={styles.button} onClick={connectWallet}>
              Open Wallet
            </div>
          )}
          <div className={styles.button} onClick={() => form.submit()}>
            Login
          </div>
        </div>
      </div>
    </PageBg>
  );
}

export default Home;
