import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "@/pages/Home";
import Leaderboard from "@/pages/Leaderboard";
import Squad from "@/pages/Squad";
import Tasks from "@/pages/Tasks";
import AiAvatar from "@/pages/AiAvatar";
import GenerateIndex from "@/pages/GenerateIndex";
import GenerateImages from "@/pages/GenerateIndex/images";
import GenerateUpload from "@/pages/GenerateIndex/upload";
import GenerateFaceVerification from "@/pages/GenerateIndex/faceVerification";
import GenerateLogin from "@/pages/GenerateIndex/login";
import GenerateQrCode from "@/pages/GenerateIndex/qrCode";
import GeneratePay from "@/pages/GenerateIndex/pay";
import Test from "@/pages/GenerateIndex/test";
function Router() {
  return (
    <Routes>
      <Route path="/" exact element={<GenerateLogin />} />
      <Route path="/homeIndex" element={<Home />} />
      <Route path="/leaderboard" element={<Leaderboard />} />
      <Route path="/squad" element={<Squad />} />
      <Route path="/tasks" element={<Tasks />} />
      <Route path="/aiavatar" element={<AiAvatar />} />
      <Route path="/generateLogin" element={<GenerateLogin />} />
      <Route path="/generateIndex" element={<GenerateIndex />} />
      <Route path="/generateImages" element={<GenerateImages />} />
      <Route path="/generateUpload" element={<GenerateUpload />} />
      <Route
        path="/generateFaceVerification"
        element={<GenerateFaceVerification />}
      />
      <Route path="/generateqrCode" element={<GenerateQrCode />} />
      <Route path="/generatePay" element={<GeneratePay />} />
      <Route path="/test" element={<Test />} />
    </Routes>
  );
}

export default Router;
