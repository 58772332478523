import React, { useImperativeHandle, forwardRef, useState } from "react";
import styles from "./index.module.css";
import { Popup, Space, Button, Toast } from "antd-mobile";
import { useNavigate, useLocation } from "react-router-dom";
import Cancle from "@/assets/pop/Cancle.svg";
import { copyTextFunction, shareUrl, textToShare } from "@/utils/tool";
const InvitePop = (props, ref) => {
  const [visible, setVisible] = useState(false);
  const open = () => {
    setVisible(true);
  };
  const close = () => {
    setVisible(false);
  };
  const copyLink = () => {
    copyTextFunction(shareUrl);
    close();
    Toast.show({
      icon: "success",
      content: "Copy Success",
    });
  };
  const Share = () => {
    const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(
      shareUrl
    )}&text=${encodeURIComponent(textToShare)}`;

    window.location.href = telegramShareUrl;
  };
  useImperativeHandle(ref, () => ({
    open,
  }));
  return (
    <Popup
      visible={visible}
      onMaskClick={close}
      onClose={close}
      bodyStyle={{
        height: "78.6vw",
        backgroundColor: "rgba(48, 48, 48, 0.9)",
        borderRadius: "10.6vw 10.6vw 0 0 ",
      }}>
      <div className={styles.PopupContainer}>
        <div className={styles.title}>
          INVITE TO MY SQUADS{" "}
          <img
            src={Cancle}
            alt="x"
            className={styles.closeImage}
            onClick={close}
          />
        </div>
        <button className={styles.inviteButton} onClick={copyLink}>
          Copy my invite link
        </button>
        <button className={styles.inviteButton} onClick={Share}>
          Share
        </button>
      </div>
    </Popup>
  );
};

export default forwardRef(InvitePop);
