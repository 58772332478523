import React, { useEffect, useState } from "react";
import styles from "./pageBg.module.css";
import logo from "@/assets/GenerateIndex/3.svg";
import { useNavigate, useLocation } from "react-router-dom";
import infoBg from "@/assets/GenerateIndex/1.svg";
import useStorage from "@/utils/storage";
import { SystemQRcodeOutline } from "antd-mobile-icons";
import { ImageViewer } from "antd-mobile";
import axios from "axios";
function PageBg(props) {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const store = useStorage();
  const { indexPage = false } = props;
  const userInfo = store.get("Mindra-generateLogin-userInfo");
  const renderFooter = (image) => {
    return (
      <div className={styles.footer}>
        <div
          className={styles.footerButton}
          onClick={async () => {
            console.log("image", image);
            // 使用 axios 获取图片数据
            const response = await axios({
              url: image,
              method: "GET",
              responseType: "blob", // 重要：设置响应类型为 blob
            });

            const blob = response.data;

            // 创建一个隐藏的<a>元素
            const aTag = document.createElement("a");
            aTag.style.display = "none";
            document.body.appendChild(aTag);

            // 创建一个URL，指向Blob对象
            const url = URL.createObjectURL(blob);

            // 设置<a>元素的href属性为刚刚创建的URL，并设置下载的文件名
            aTag.href = url;
            aTag.download = "image";

            // 模拟点击<a>元素，触发下载
            aTag.click();

            // 清理
            document.body.removeChild(aTag);
            URL.revokeObjectURL(url);
          }}>
          Save
        </div>
      </div>
    );
  };
  return (
    <div className={styles.pageBg}>
      {indexPage && <img src={infoBg} alt="" className={styles.infoBg} />}
      <div className={styles.header}>
        <img
          src={logo}
          alt=""
          className={styles.logo}
          onClick={() => navigate({ pathname: "/" })}
        />
        {userInfo && userInfo?.qr_code && (
          <div
            style={{ marginLeft: "1vw" }}
            onClick={() => {
              setVisible(true);
            }}>
            <SystemQRcodeOutline fontSize={24} />
          </div>
        )}
      </div>
      <ImageViewer
        classNames={{
          mask: "customize-mask",
          body: "customize-body",
        }}
        image={userInfo?.qr_code}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        renderFooter={renderFooter}
      />
      <div className={styles.childrenPage}> {props.children}</div>
    </div>
  );
}

export default PageBg;
