import React, { useEffect, useState, useRef } from "react";
import TabBar from "@/components/TabBar";
import styles from "./index.module.css";
import { getTgUserId } from "@/utils/tool.js";
import { userInfo, getUsers } from "@/api/index";
import useStorage from "@/utils/storage";
import LeaderboardItem from "@/components/LeaderboardItem";
function Leaderboard() {
  const store = useStorage();
  const [userInfoData, setUserInfoData] = useState(
    store.get("Mindra-Userinfo")
  );
  const [holders, setHolders] = useState("0");
  const [list, setList] = useState([]);
  const getUserInfo = async () => {
    let res = await userInfo(getTgUserId());
    console.log("res", res);
    if (res.code === 200) {
      let data = res?.data;
      setUserInfoData(data);
      store.set("Mindra-Userinfo", data);
    }
  };
  const getList = async () => {
    let res = await getUsers(1, 100);
    if (res.code === 200) {
      let data = res?.data;
      setList(data?.users);
      setHolders(data?.total);
      console.log("---", data);
    }
  };
  useEffect(() => {
    getUserInfo();
    getList();
  }, []);

  return (
    <div className={styles.LeaderboardContainer}>
      <div className={styles.title}>POINTS LEADERBOARD</div>
      <LeaderboardItem
        showBordered={true}
        rank={userInfoData?.rank}
        name={userInfoData?.name}
        avatar={userInfoData?.profile_photo}
        coin={userInfoData?.points}
        />
      <div className={styles.PunnyHolders}>
        Points Holders
        <span>{holders}</span>
      </div>
      {list.map((item, index) => {
        return (
          <div className={styles.LeaderboardItem}>
            <LeaderboardItem
              rank={item?.rank}
              name={item?.name}
              avatar={item?.profile_photo}
              coin={item?.points}
            />
          </div>
        );
      })}
      <TabBar />
    </div>
  );
}

export default Leaderboard;
