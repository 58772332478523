import React, { useEffect, useState } from "react";
import { PlugMobileProvider } from "@funded-labs/plug-mobile-sdk";
import bg1 from "@/assets/2.svg";
import image2 from "@/assets/5.svg";
import image3 from "@/assets/10.svg";
import image4 from "@/assets/13.svg";
import image5 from "@/assets/12.svg";
import image6 from "@/assets/11.svg";
import image7 from "@/assets/4.svg";
import image8 from "@/assets/6.svg";
import image10 from "@/assets/tasks/reward10.svg";
import { formatNumberToThousands, getTgUserId } from "@/utils/tool.js";
import { SpinLoading } from "antd-mobile";
import { userInfo, connectWalletApi } from "@/api/index";
import useStorage from "@/utils/storage";
import StartScreen from "@/components/StartScreen";
import TabBar from "@/components/TabBar";
import styles from "./index.module.css";
function Home() {
  const store = useStorage();
  const [provider, setProvider] = useState([]);
  const [isProviderInitialized, setIsProviderInitialized] = useState(false);
  const [wallButtonLoading, setWallButtonLoading] = useState(false);
  const [taskDone, setTaskDone] = useState(false);
  const [taskList, setTaskList] = useState([
    {
      isDone: false,
      icon: image3,
      rewardsTitle: "Account age",
      key: "registered_year",
      rewards: 1000,
      rewardsPoints: "",
      loading: false,
    },
    {
      isDone: false,
      icon: image2,
      rewards: 1000,
      rewardsTitle: "Telegram Premium",
      key: "tg_paid_user",
      rewardsPoints: "",
      loading: false,
    },
    {
      isDone: false,
      icon: image4,
      rewards: 500,
      rewardsTitle: "Connect Wallet",
      key: "wallet_connected",
      rewardsPoints: "",
      loading: false,
    },
    {
      isDone: false,
      icon: image5,
      rewards: 500,
      rewardsTitle: "ICP Balance > 1",
      key: "icp_balance",
      rewardsPoints: "",
      loading: false,
    },
    {
      isDone: false,
      icon: image6,
      rewards: 1000,
      rewardsTitle: "Make an ICP Transcation",
      key: "transfer_made",
      rewardsPoints: "",
      loading: false,
    },

    {
      isDone: false,
      icon: image7,
      rewards: 100,
      rewardsTitle: "Invite Friends",
      key: "invitees",
      rewardsPoints: "",
      loading: false,
    },
    {
      isDone: false,
      icon: image8,
      rewards: 500,
      rewardsTitle: "Follow us on X",
      key: "follow_x_ai",
      rewardsPoints: "",
      loading: false,
    },
    {
      isDone: false,
      icon: image2,
      rewards: 500,
      rewardsTitle: "Join Mindra.AI Community",
      key: "join_tg_ai",
      rewardsPoints: "",
      loading: false,
    },
    {
      isDone: false,
      icon: image8,
      rewards: 500,
      rewardsTitle: "Follow ICP on X",
      key: "follow_x_icp",
      rewardsPoints: "",
      loading: false,
    },
    {
      isDone: false,
      icon: image2,
      rewards: 500,
      rewardsTitle: "Join ICP Community",
      key: "join_tg_icp",
      rewardsPoints: "",
      loading: false,
    },
    {
      isDone: false,
      icon: image7,
      rewards: 2000,
      rewardsTitle: "Mint User AI Avatar NFTs on ICP",
      key: "mint_nft",
      rewardsPoints: "",
      loading: false,
    },
  ]);
  const [userInfoData, setUserInfoData] = useState(
    store.get("Mindra-Userinfo")
  );

  useEffect(() => {
    const initializeProvider = async () => {
      if (isProviderInitialized) {
        return;
      }
      const isMobile = PlugMobileProvider.isMobileBrowser();
      console.log(`Is mobile: ${isMobile}`);

      var newProvider = new PlugMobileProvider({
        debug: true,
        walletConnectProjectId: "af7a628b750917a56ab5648a10040df4",
        window: window,
      });
      console.log(`Provider initialized: ${newProvider}`);

      try {
        await newProvider.initialize();
        console.log("Provider initialized successfully");
        setIsProviderInitialized(true); // Set provider initialization flag
        setProvider(newProvider);
      } catch (error) {
        console.log(`Provider initialization error: ${error}`);
      }
    };
    if (getTgUserId()) {
      getUserInfo();
    }
    if (!userInfoData.wallet_connected) {
      initializeProvider();
    }
  }, []);

  useEffect(() => {
    const checkPairingAndContinue = async () => {
      if (!isProviderInitialized) {
        return;
      }
      console.log("provider.isPaired()", provider.isPaired());

      if (provider && provider.isPaired() && !userInfoData.wallet_connected) {
        let walletData = provider.walletConnectSession;
        let principal_id = "";
        if (walletData?.namespaces?.icp?.accounts.length > 0) {
          let dataArray = walletData?.namespaces?.icp?.accounts[0].split(":");
          principal_id = dataArray[dataArray.length - 1];
        }
        let res = await connectWalletApi(getTgUserId(), principal_id);
        if (res?.code === 200) {
          setUserStorage(res?.data);
        }
      }
    };

    const intervalId = setInterval(checkPairingAndContinue, 1000); // Adjust the interval as needed
    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [provider]);

  const connectWallet = () => {
    if (wallButtonLoading) return;
    setWallButtonLoading(true);
    if (!isProviderInitialized) {
      setWallButtonLoading(false);
      return;
    }
    if (provider && !provider.isPaired()) {
      if (!provider.provider) {
        setWallButtonLoading(false);
        return;
      }
      if (provider.isWallectConnectPaired) {
        setWallButtonLoading(false);
        return;
      }
      if (!provider.wcPairUri) {
        setWallButtonLoading(false);
        return;
      }
      if (!provider.connectionPromise) {
        setWallButtonLoading(false);
        return;
      }
      setWallButtonLoading(false);
      const url = `https://plugwallet.ooo/wc?uri=${provider.wcPairUri}`;
      window.location = url;
      console.log("Redirecting to WalletConnect pairing URL");
    }

    const intervalId = setInterval(() => {
      clearInterval(intervalId);

      // Trigger connectionPromise logic here
      provider
        .connectionPromise()
        .then((session) => {
          provider.isWallectConnectPaired = true;
          provider.walletConnectSession = session;
          console.log("WalletConnect session established");
        })
        .catch((error) => {
          console.log(`Connection error: ${error}`);
        })
        .finally(() => {
          setWallButtonLoading(false);
        });
    }, 1000); // Check every 1 second
  };

  const pair = async (mobileProvider) => {
    if (!mobileProvider.provider) {
      return;
    }
    if (mobileProvider.isWallectConnectPaired) {
      return;
    }
    if (!mobileProvider.wcPairUri) {
      return;
    }
    if (!mobileProvider.connectionPromise) {
      return;
    }

    const url = `https://plugwallet.ooo/wc?uri=${mobileProvider.wcPairUri}`;
    window.location = url;

    // try {
    //     const session = await mobileProvider.connectionPromise();
    //     mobileProvider.isWallectConnectPaired = true;
    //     mobileProvider.walletConnectSession = session;
    //     return true;
    // } catch (error) {
    //     return Promise.reject(error);
    // }
  };

  const setTaskData = (data) => {
    let taskListData = [...taskList];
    taskListData.map((item) => {
      if (item.key === "registered_year" && data[item.key] >= 1) {
        item.isDone = true;
        item.rewardsPoints = data[item.key] * item?.rewards;
      }
      if (item.key === "invitees" && data[item.key].length >= 1) {
        item.isDone = true;
        item.rewardsPoints = data[item.key].length * item?.rewards;
      }
      if (
        item.key &&
        data[item.key] &&
        item.key !== "invitees" &&
        item.key !== "registered_year"
      ) {
        item.isDone = true;
        item.rewardsPoints = item?.rewards;
      }
    });
    setTaskDone(true);
    setTaskList(taskListData);
  };
  useEffect(() => {
    if (userInfoData) {
      setTaskData(userInfoData);
    }
  }, [userInfoData]);
  const setUserStorage = (data) => {
    setUserInfoData(data);
    store.set("Mindra-Userinfo", data);
  };
  const getUserInfo = async () => {
    let res = await userInfo(getTgUserId());
    console.log("getUserInfo====res", res);
    if (res.code === 200) {
      let data = res?.data;
      setUserStorage(data);
    }
  };
  useEffect(() => {
    if (getTgUserId()) {
      getUserInfo();
    }
  }, [getTgUserId()]);

  if (!userInfoData) {
    return (
      <div className={styles.loadingContainer}>
        <SpinLoading color="primary" />
      </div>
    );
  }
  return (
    <div className={styles.PageIndex}>
      <div className={styles.headerContainer}>
        <img src={bg1} alt="bg1" className={styles.bg1} />
        Welcome to Mindra.AI on ICP
      </div>
      <div className={styles.avatarContainer}>
        <StartScreen />
        <div className={styles.incomeContainer}>
          {formatNumberToThousands(userInfoData?.points)}
        </div>
        <div className={styles.boinContainer}>Points</div>
      </div>
      {userInfoData?.wallet_connected ? (
        <div className={styles.takeUp}></div>
      ) : (
        <button onClick={connectWallet} className={styles.connectButton}>
          {wallButtonLoading && (
            <div className={styles.connectButtonLoading}>
              <SpinLoading color="primary" style={{ "--size": "20px" }} />
            </div>
          )}
        </button>
      )}

      <div className={styles.taskContainer}>
        <div className={styles.taskTitle}>Your rewards</div>
        {taskDone &&
          taskList.map((task, index) => {
            return (
              <div className={styles.taskItem} key={index}>
                <img
                  className={styles.taskItemImg}
                  src={task?.icon}
                  alt="icon"
                />
                <div className={styles.taskItemTitleContainer}>
                  <div className={styles.taskItemTitleTopRewards}>
                    {task?.rewardsTitle}
                  </div>
                </div>
                <div className={styles.taskItemRewardsMoney}>
                  + {task?.rewardsPoints || 0} points
                </div>
              </div>
            );
          })}
      </div>
      <TabBar />
    </div>
  );
}

export default Home;
