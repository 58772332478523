import React, { useEffect, useState,useRef } from "react";
import { PlugMobileProvider } from "@funded-labs/plug-mobile-sdk";
import tasks1 from "@/assets/tasks/1.svg";
import tasks2 from "@/assets/tasks/2.svg";
import tasks3 from "@/assets/tasks/3.svg";
import tasks4 from "@/assets/tasks/4.svg";
import tasks5 from "@/assets/tasks/5.svg";
import tasks6 from "@/assets/tasks/6.svg";
import tasks7 from "@/assets/tasks/7.svg";
import tasks8 from "@/assets/tasks/8.svg";
import tasks9 from "@/assets/tasks/9.svg";
import tasks10 from "@/assets/tasks/10.svg";
import {
  formatNumberToThousands,
  getTgUserId,
  getTgUserName,
} from "@/utils/tool.js";
import { SpinLoading } from "antd-mobile";
import { userInfo, connectWalletApi, doneTasks } from "@/api/index";
import useStorage from "@/utils/storage";
import TabBar from "@/components/TabBar";
import styles from "./index.module.css";
import OpenPop from "@/components/OpenPop";
function Tasks() {
  const store = useStorage();
  const userName = getTgUserName();
  const [provider, setProvider] = useState([]);
  const [isProviderInitialized, setIsProviderInitialized] = useState(false);
  const PopRef = useRef(null);
  const [taskList, setTaskList] = useState([
    {
      title: "Account age",
      points: "*1000 points",
      key: "registered_year",
      isDone: false,
      imagesIcon: tasks1,
      buttonText: "Start",
      func: (index) => {
        setBtnLoading(index);
        getUserInfo();
      },
      loading: false,
    },
    {
      title: "Telegram Premium",
      points: "+1000 points",
      key: "tg_paid_user",
      isDone: false,
      imagesIcon: tasks2,
      buttonText: "Start",
      func: (index) => {
        setBtnLoading(index);
        getUserInfo();
      },
      loading: false,
    },
    {
      title: "Connect Wallet",
      points: "+500 points",
      imagesIcon: tasks3,
      buttonText: "Start",
      key: "wallet_connected",
      isDone: false,
      func: (index) => {
        setBtnLoading(index);
        getUserInfo();
        connectWallet();
      },
      loading: false,
    },
    {
      title: "ICP Balance > 1",
      points: "+500 points",
      buttonText: "Start",
      key: "icp_balance",
      imagesIcon: tasks4,
      isDone: false,
      func: (index) => {
        setBtnLoading(index);
        getUserInfo();
      },
      loading: false,
    },
    {
      title: "Make an ICP Transcation",
      points: "+1000 points",
      key: "transfer_made",
      imagesIcon: tasks5,
      buttonText: "Start",
      isDone: false,
      func: (index) => {
        setBtnLoading(index);
        getUserInfo();
        setTimeout(() => {
          window.location = "https://plugwallet.ooo/wc";
        }, 100);
      },
      loading: false,
    },
    {
      title: "Follow us on X",
      points: "+500 points",
      imagesIcon: tasks6,
      key: "follow_x_ai",
      buttonText: "Start",
      isDone: false,

      func: async (index) => {
        setBtnLoading(index);
        await doneTasks(getTgUserId(), "follow_x_ai");
        setTimeout(() => {
          window.location = "https://x.com/Mindra_AI";
        }, 100);
        getUserInfo();
      },
      loading: false,
    },
    {
      title: "Join Mindra.AI Community",
      points: "+500 points",
      imagesIcon: tasks7,
      key: "join_tg_ai",
      buttonText: "Start",
      isDone: false,

      func: async (index) => {
        setBtnLoading(index);
        await doneTasks(getTgUserId(), "join_tg_ai");
        setTimeout(() => {
          window.location = "https://t.me/MindraAi";
        }, 100);
        getUserInfo();
      },
      loading: false,
    },
    {
      title: "Follow ICP on X",
      points: "+500 points",
      key: "follow_x_icp",
      imagesIcon: tasks8,
      buttonText: "Start",
      isDone: false,

      func: async (index) => {
        setBtnLoading(index);
        await doneTasks(getTgUserId(), "follow_x_icp");
        setTimeout(() => {
          window.location = "https://x.com/dfinity";
        }, 100);
        getUserInfo();
      },
      loading: false,
    },
    {
      title: "Join ICP Community",
      points: "+500 points",
      key: "join_tg_icp",
      imagesIcon: tasks9,
      buttonText: "Start",
      isDone: false,

      func: async (index) => {
        setBtnLoading(index);
        await doneTasks(getTgUserId(), "join_tg_icp");
        setTimeout(() => {
          window.location = "https://t.me/Official_ICP";
        }, 100);
        getUserInfo();
      },
      loading: false,
    },
    {
      title: "Mint User AI Avatar NFTs on ICP",
      points: "+2000 points",
      key: "mint_nft",
      imagesIcon: tasks10,
      buttonText: "Start",
      isDone: false,

      func: async (index) => {
        setBtnLoading(index);
        PopRef.current?.open();
        getUserInfo();
      },
      loading: false,
    },
  ]);
  const [taskDone, setTaskDone] = useState(false);
  const [points,setPoint]= useState(0);
  const setBtnLoading = (index) => {
    let list = [...taskList];
    list[index].loading = true;
    setTaskList(list);
    setTimeout(() => {
      let list = [...taskList];
      list[index].loading = false;
      setTaskList(list);
    }, 1000);
  };
  const [userInfoData, setUserInfoData] = useState(
    store.get("Mindra-Userinfo")
  );
  useEffect(() => {
    const initializeProvider = async () => {
      if (isProviderInitialized) {
        return;
      }
      const isMobile = PlugMobileProvider.isMobileBrowser();
      console.log(`Is mobile: ${isMobile}`);

      var newProvider = new PlugMobileProvider({
        debug: true,
        walletConnectProjectId: "af7a628b750917a56ab5648a10040df4",
        window: window,
      });
      console.log(`Provider initialized: ${newProvider}`);

      try {
        await newProvider.initialize();
        console.log("Provider initialized successfully");
        setIsProviderInitialized(true); // Set provider initialization flag
        setProvider(newProvider);
      } catch (error) {
        console.log(`Provider initialization error: ${error}`);
      }
    };

    initializeProvider();
  }, []);

  useEffect(() => {
    const checkPairingAndContinue = async () => {
      if (!isProviderInitialized) {
        return;
      }
      console.log("provider.isPaired()", provider.isPaired());

      if (provider && provider.isPaired() && !userInfoData.wallet_connected) {
        let walletData = provider.walletConnectSession;
        let principal_id = "";
        if (walletData?.namespaces?.icp?.accounts.length > 0) {
          let dataArray = walletData?.namespaces?.icp?.accounts[0].split(":");
          principal_id = dataArray[dataArray.length - 1];
        }
        let res = await connectWalletApi(getTgUserId(), principal_id);
        if (res?.code === 200) {
          setUserStorage(res?.data);
        }
      }
    };

    const intervalId = setInterval(checkPairingAndContinue, 1000); // Adjust the interval as needed

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [provider]);

  const connectWallet = () => {
    if (!isProviderInitialized) {
      return;
    }
    if (provider && !provider.isPaired()) {
      if (!provider.provider) {
        return;
      }
      if (provider.isWallectConnectPaired) {
        return;
      }
      if (!provider.wcPairUri) {
        return;
      }
      if (!provider.connectionPromise) {
        return;
      }
      const url = `https://plugwallet.ooo/wc?uri=${provider.wcPairUri}`;
      window.location = url;
      console.log("Redirecting to WalletConnect pairing URL");
    }

    const intervalId = setInterval(() => {
      clearInterval(intervalId);

      // Trigger connectionPromise logic here
      provider
        .connectionPromise()
        .then((session) => {
          provider.isWallectConnectPaired = true;
          provider.walletConnectSession = session;
          console.log("WalletConnect session established");
        })
        .catch((error) => {
          console.log(`Connection error: ${error}`);
        });
    }, 1000); // Check every 1 second
  };

  const pair = async (mobileProvider) => {
    if (!mobileProvider.provider) {
      return;
    }
    if (mobileProvider.isWallectConnectPaired) {
      return;
    }
    if (!mobileProvider.wcPairUri) {
      return;
    }
    if (!mobileProvider.connectionPromise) {
      return;
    }

    const url = `https://plugwallet.ooo/wc?uri=${mobileProvider.wcPairUri}`;
    window.location = url;
  };

  const setTaskData = (data) => {
    let taskListData = [...taskList];
    taskListData.map((item) => {
      if (item.key === "registered_year" && data[item.key] >= 1) {
        item.isDone = true;
        item.rewardsPoints = data[item.key] * item?.rewards;
      }
      if (item.key === "invitees" && data[item.key].length >= 1) {
        item.isDone = true;
        item.rewardsPoints = data[item.key].length * item?.rewards;
      }
      if (
        item.key &&
        data[item.key] &&
        item.key !== "invitees" &&
        item.key !== "registered_year"
      ) {
        item.isDone = true;
        item.rewardsPoints = item?.rewards;
      }
    });
    setTaskDone(true);
    setTaskList(taskListData);
  };
  useEffect(() => {
    setTaskData(userInfoData);
  }, [userInfoData]);
  const setUserStorage = (data) => {
    setUserInfoData(data);
    setPoint(data?.points||0)
    store.set("Mindra-Userinfo", data);
  };
  const getUserInfo = async () => {
    let res = await userInfo(getTgUserId());
    if (res.code === 200) {
      let data = res?.data;
      setUserStorage(data);
    }
  };
  useEffect(() => {
    getUserInfo();
  }, []);

  if (!userInfoData) {
    return (
      <div className={styles.loadingContainer}>
        <SpinLoading color="primary" />
      </div>
    );
  }
  return (
    <div className={styles.TasksContainer}>
      <div className={styles.titleContainer}>
        <div className={styles.name}>{userName}</div>
        <div className={styles.coin}>
          {formatNumberToThousands(points)}
          <span className={styles.points}>POINTS</span>
        </div>
      </div>
      <OpenPop ref={PopRef}/>
      {taskDone && (
        <div className={styles.taskListContainer}>
          <div className={styles.taskTitle}>Tasks</div>
          {taskList.map((task, index) => {
            if (task?.isDone) {
              return null;
            }
            return (
              <div className={styles.taskItem} key={index}>
                <div className={styles.taskItemTitleContainer}>
                  <div className={styles.taskItemTitleTop}>{task?.title}</div>
                  <div className={styles.taskItemDesc}>{task?.points}</div>
                  <button
                    onClick={() => task?.func(index)}
                    className={styles.button}>
                    {task?.buttonText}

                    {task?.loading && (
                      <div className={styles.connectButtonLoading}>
                        <SpinLoading
                          color="primary"
                          style={{ "--size": "20px" }}
                        />
                      </div>
                    )}
                  </button>
                </div>
                <img src={task?.imagesIcon} className={styles?.taskItemImg} />
              </div>
            );
          })}
        </div>
      )}

      <TabBar />
    </div>
  );
}

export default Tasks;
