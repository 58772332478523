import React, { useState, useRef } from "react";
import { convertToFileBase64, removeBase64Prefix } from "@/utils/tool.js";
import { qrScan } from "@/api/index";
import PageBg from "./pageBg";
import styles from "./qrCode.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import images4 from "@/assets/GenerateIndex/4.svg";
import { SpinLoading } from "antd-mobile";
function Home() {
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [base64, setBase64] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    let base64 = await convertToFileBase64(file);
    setBase64(base64);
  };

  const handleUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const next = async () => {
    setLoading(true);
    let res = await qrScan({ image: removeBase64Prefix(base64) });
    console.log("res", res);
    setLoading(false);
    if (res.code === 200) {
      navigate({ pathname: "/generateIndex" });
    }
  };
  return (
    <PageBg>
      <div className={styles.GenerateIndex}>
        <div className={styles.actionContainer}>
          <img
            src={images4}
            alt=""
            className={styles.backIcon}
            onClick={() => navigate(-1)}
          />
        </div>
        <div className={styles.title}>Upload QR code</div>
        <div className={styles.desc}>
          Upload QR code image and make a transfer
        </div>
        <div className={styles.Upload} onClick={handleUpload}>
          {base64 && <img src={base64} alt="" />}
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
            ref={inputRef}
            accept=".jpg, .jpeg, .png,"
          />
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.button} onClick={next}>
            Next
            {loading && (
              <div className={styles.connectButtonLoading}>
                <SpinLoading color="primary" style={{ "--size": "20px" }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </PageBg>
  );
}

export default Home;
