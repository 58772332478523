const bowser = require("bowser");
// 数字转千分位 3,000
export function formatNumberToThousands(num) {
  // return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
  return num.toLocaleString();
}
//获取网址参数
export const getUrlKey = (name) => {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        window.location.href
      ) || [, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
};
// URL参数 转对象
export function getQueryParams(url) {
  // 使用正则表达式提取URL参数
  const paramsString = url.split("?")[1];
  const params = {};

  if (paramsString) {
    const paramsPairs = paramsString.split("&");
    paramsPairs.forEach(function (pair) {
      const [key, value] = pair.split("=");
      params[decodeURIComponent(key)] = decodeURIComponent(value || "");
    });
  }

  return params;
}
// 对象转URL参数
export function objectToQueryString(obj) {
  return Object.keys(obj)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]))
    .join("&");
}
// 获取tg 用户信息
export function getTgUserInfo() {
  console.log(
    "window?.Telegram?.WebApp?.initData-1",
    window?.Telegram?.WebApp?.initData
  );
  if (window?.Telegram?.WebApp?.initData) {
    window.localStorage.setItem(
      "tgUserInfo",
      JSON.stringify(getQueryParams(`?${window?.Telegram?.WebApp?.initData}`))
    );

    return getQueryParams(`?${window?.Telegram?.WebApp?.initData}`);
  } else {
    return null;
  }
}
// 获取tg 用户id
export function getTgUserId() {
  let tgUserInfo = window.localStorage.getItem("tgUserInfo")
    ? JSON.parse(window.localStorage.getItem("tgUserInfo"))
    : null;

  console.log(
    "window?.Telegram?.WebApp?.initData-2",
    window?.Telegram?.WebApp?.initData
  );
  if (window.location.host === "localhost:3000") {
    return "5216709257";
  }
  console.log("-tg 用户信息--111", tgUserInfo, getTgUserInfo()?.user);
  if (window?.Telegram?.WebApp?.initData || getTgUserInfo() || tgUserInfo) {
    return tgUserInfo
      ? JSON.parse(tgUserInfo?.user)?.id
      : JSON.parse(getTgUserInfo()?.user)?.id;
  }
  return "";
}
// 获取tg 用户id
export function getTgUserName() {
  let tgUserInfo = window.localStorage.getItem("tgUserInfo")
    ? JSON.parse(window.localStorage.getItem("tgUserInfo"))
    : null;
  console.log(
    "window?.Telegram?.WebApp?.initData-3",
    window?.Telegram?.WebApp?.initData
  );
  if (window.location.host === "localhost:3000") {
    return "Localhost Show Name";
  }
  if (window?.Telegram?.WebApp?.initData || tgUserInfo) {
    if (tgUserInfo) {
      return (
        JSON.parse(tgUserInfo?.user)?.last_name +
        " " +
        JSON.parse(tgUserInfo?.user)?.first_name
      );
    } else {
      return (
        JSON.parse(getTgUserInfo()?.user)?.last_name +
        " " +
        JSON.parse(getTgUserInfo()?.user)?.first_name
      );
    }
  }
}
// 一键复制
export function copyTextFunction(value) {
  let textarea = document.createElement("textarea"); //创建临时文本区域元素
  textarea.value = value; //将要复制的内容赋值给该文本区域
  document.body.appendChild(textarea); //添加到页面中
  textarea.select(); //选中文本区域的内容
  document.execCommand("copy"); //执行复制命令
  textarea.style.display = "none"; //textarea隐藏，否则会出现在页面上
  textarea.disabled = true; //textarea禁用
  console.log("已复制");
}
export const shareUrl = `http://t.me/Mindra_AI_Bot/Mindra_AI_App?startapp=${getTgUserId()}`;
export const textToShare = "Mindra.AI";

export function isDesktopBrowser() {
  const detectedBrowser = bowser.getParser(window.navigator.userAgent);
  const isMobile = detectedBrowser.isMobile;
  const userAgent = navigator.userAgent;

  // 检查用户代理字符串中是否包含移动设备关键词
  const mobileKeywords = [
    "iPhone",
    "iPad",
    "iPod",
    "Android",
    "BlackBerry",
    "Windows Phone",
  ];
  let containsMobileKeyword = false;
  for (let keyword of mobileKeywords) {
    if (userAgent.indexOf(keyword) !== -1) {
      containsMobileKeyword = true;
      break;
    }
  }

  // 如果检测到移动关键词，则进一步检查窗口大小
  const minWidth = 768; // 常见的最小桌面宽度
  const minHeight = 480; // 常见的最小桌面高度
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  // 结合两种检查结果
  const isDesktop =
    !containsMobileKeyword || (width >= minWidth && height >= minHeight);

  return isDesktop;
}

export function formatStringWithEllipsis(str, numCharsFront, numCharsBack) {
  if (!str) return "";
  // 检查字符串是否足够长，以包含前后显示的部分
  if (str.length <= numCharsFront + numCharsBack) {
    return str; // 如果不够长，直接返回原字符串
  }

  // 获取前numCharsFront个字符
  const frontPart = str.substring(0, numCharsFront);

  // 获取后numCharsBack个字符
  const endPart = str.substring(str.length - numCharsBack);

  // 构造最终的字符串
  const formattedStr = frontPart + "..." + endPart;

  return formattedStr;
}

export async function compressImage(file) {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = async function (event) {
      let img = new Image();
      img.onload = async function () {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");

        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        let quality = 1.0;
        let originalSize = file.size;

        do {
          let dataUrl = canvas.toDataURL("image/jpeg", quality);
          let compressedBlob = dataURLToBlob(dataUrl);
          originalSize = compressedBlob.size;

          if (originalSize > 1 * 1024 * 1024 && quality > 0.1) {
            quality -= 0.1;
          }
        } while (originalSize > 1 * 1024 * 1024 && quality > 0.1);

        resolve(canvas.toDataURL());
      };
      img.src = event.target.result;
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}

export function dataURLToBlob(dataUrl) {
  let arr = dataUrl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n--;
  }
  return new Blob([u8arr], { type: mime });
}

export async function convertToFileBase64(file) {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = function (event) {
      resolve(event.target.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}

export function base64ToBlob(base64) {
  let parts = base64.split(";base64,");
  let contentType = parts[0].split(":")[1];
  let byteCharacters = atob(parts[1]);
  let byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  let byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}

export function removeBase64Prefix(base64String) {
  // 使用正则表达式匹配data URL的前缀部分
  var prefixRegex = /^data:image\/\w+;base64,/;

  // 检查字符串是否包含匹配的前缀，并去除它
  if (prefixRegex.test(base64String)) {
    return base64String.replace(prefixRegex, "");
  }

  // 如果没有前缀，就返回原始字符串
  return base64String;
}
