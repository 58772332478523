import React, { useState, useEffect } from "react";
import { PlugMobileProvider } from "@funded-labs/plug-mobile-sdk";
const canisterId = "er6gs-wiaaa-aaaan-qmvea-cai";
const whitelist = [canisterId];
const host = "https://a4gq6-oaaaa-aaaab-qaa4q-cai.raw.icp0.io";
const ICPTransferForm = () => {
  const [receiverAddress, setReceiverAddress] = useState("");
  const [transferAmount, setTransferAmount] = useState("");
  const [transferResult, setTransferResult] = useState(null);
  const [agent, setAgent] = useState(null);
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    const initializePlugMobile = async () => {
      if (PlugMobileProvider.isMobileBrowser()) {
        const newProvider = new PlugMobileProvider({
          debug: true,
          walletConnectProjectId: canisterId, // 替换为你的WalletConnect项目ID
          window: window,
        });

        await newProvider.initialize();
        setProvider(newProvider);

        if (newProvider.isPaired()) {
          const newAgent = await newProvider.createAgent({
            host: host,
            targets: [],
          });
          setAgent(newAgent);
        }
      }
    };

    initializePlugMobile();
  }, []);

  const handleTransfer = async () => {
    if (agent) {
      try {
        const response = await agent.requestTransfer({
          to: receiverAddress,
          amount: parseInt(transferAmount),
          fee: 100_000_000, // 默认转账费用设置为100E8s
        });
        setTransferResult(`成功发起转账请求: ${JSON.stringify(response)}`);
      } catch (error) {
        setTransferResult(`转账失败: ${error.message}`);
      }
    }
  };

  return (
    <div>
      <h2>ICP转账</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <label htmlFor="receiverAddress">收款人地址:</label>
        <input
          type="text"
          id="receiverAddress"
          value={receiverAddress}
          onChange={(e) => setReceiverAddress(e.target.value)}
          required
        />
        <br />
        <label htmlFor="transferAmount">转账金额 (E8s):</label>
        <input
          type="number"
          id="transferAmount"
          value={transferAmount}
          onChange={(e) => setTransferAmount(e.target.value)}
          required
        />
        <br />
        <div
          onClick={handleTransfer}
          style={{ width: "300px", height: "300px", border: "1px solid red" }}>
          发起转账
        </div>
      </form>
      {transferResult && <p>{transferResult}</p>}
    </div>
  );
};

export default ICPTransferForm;
