import React, { useEffect, useState } from "react";
import image1 from "@/assets/3.svg";
import useStorage from "@/utils/storage";
import styles from "./index.module.css";
import { getQueryParams, objectToQueryString } from "@/utils/tool";
import { userInvite } from "@/api/index";

import { formatNumberToThousands, getTgUserId } from "@/utils/tool.js";
function StartScreen() {
  const store = useStorage();
  const searchParams = new URLSearchParams(window.location.search);
  const isStartScreen = searchParams.get("StartScreen");
  const inviteesId = searchParams.get("tgWebAppStartParam");
  const [isNormal, setIsNormal] = useState(false);
  const [lgooClass, setLogoClass] = useState("logoFadeIn");
  const [StartScreenClass, setStartScreenClass] = useState("StartScreen");
  console.log("isStartScreen", window.location.search, isStartScreen);
  useEffect(() => {
    if (getTgUserId() !== inviteesId) {
      userInvite(getTgUserId(), inviteesId).then((res) => {
        console.log("userInvite", res);
      });
    }
    store.set("Mindra-inviteesId", inviteesId);
  }, [inviteesId]);
  const Play = () => {
    setTimeout(() => {
      setLogoClass("logoTr");
      setTimeout(() => {
        setStartScreenClass("StartScreenfadeOut");
        setTimeout(() => {
          setIsNormal(true);
          finallyUrl();
        }, 1000);
      }, 1000);
    }, 2200);
  };
  const finallyUrl = () => {
    if (window?.Telegram?.WebApp?.initData) {
      window.localStorage.setItem(
        "tgUserInfo",
        JSON.stringify(getQueryParams(`?${window?.Telegram?.WebApp?.initData}`))
      );
    }
    let obj = getQueryParams(window.location.href);
    delete obj.StartScreen;
    window.history.pushState(null, "", `?${objectToQueryString(obj)}`);
  };
  useEffect(() => {
    if (isStartScreen) {
      setIsNormal(false);
      Play();
    } else {
      setIsNormal(true);
    }
  }, [isStartScreen]);

  return (
    <div className={styles.logoConatiner}>
      {isNormal ? (
        <img src={image1} alt="image1" className={styles.logo} />
      ) : (
        <div className={styles[StartScreenClass]}>
          <img src={image1} alt="image1" className={styles[lgooClass]} />
        </div>
      )}
    </div>
  );
}

export default StartScreen;
