import React, { useImperativeHandle, forwardRef, useState } from "react";
import styles from "./index.module.css";
import { Popup, Space, Button, Toast } from "antd-mobile";
import { useNavigate, useLocation } from "react-router-dom";
import Cancle from "@/assets/pop/Cancle.svg";
import { copyTextFunction, shareUrl, textToShare } from "@/utils/tool";
import images8 from "@/assets/GenerateIndex/8.svg";
import images9 from "@/assets/GenerateIndex/9.svg";
const InvitePop = (props, ref) => {
  const { data } = props;
  console.log("data", data);
  const [visible, setVisible] = useState(false);
  const open = () => {
    setVisible(true);
  };
  const close = () => {
    setVisible(false);
  };
  const done = () => {
    close();
    Toast.show({
      icon: "success",
      content: "Success",
    });
  };

  useImperativeHandle(ref, () => ({
    open,
  }));
  return (
    <Popup
      visible={visible}
      onMaskClick={close}
      onClose={close}
      bodyStyle={{
        height: "80vh",
        backgroundColor: "rgba(48, 48, 48, 0.9)",
        borderRadius: "10.6vw 10.6vw 0 0 ",
      }}>
      <div className={styles.PopupContainer}>
        <div className={styles.title}>
          确认支付{" "}
          <img
            src={Cancle}
            alt="x"
            className={styles.closeImage}
            onClick={close}
          />
        </div>
        <div className={styles.item}>
          <div className={styles.itemName}>转账至</div>
          <div className={styles.inputContainer}>
            <span style={{ display: "flex", alignItems: "center" }}>
              <img src={images8} style={{ marginRight: "10px" }} /> bit88889999
            </span>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemName}>金额</div>
          <div className={styles.inputContainer}>
            <span>
              {data?.numValue} {data?.value}
            </span>
            <span style={{ color: "rgba(248, 250, 252, 1)" }}>= $100</span>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemName}>支付方式</div>
          <div className={styles.inputContainer}>
            现货钱包 <img src={images9} alt="" />
          </div>
          <div className={styles.inputContainer}>
            {data?.numValue}
            {data?.value} <img src={images9} alt="" />
          </div>
        </div>

        <button className={styles.inviteButton} onClick={done}>
          确认
        </button>
      </div>
    </Popup>
  );
};

export default forwardRef(InvitePop);
