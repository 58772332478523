import React, { useEffect, useState, useRef } from "react";
import useStorage from "@/utils/storage";
import PageBg from "./pageBg";
import styles from "./pay.module.css";
import { Picker, Button, Space, Toast, NumberKeyboard } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { aiduser } from "@/api/index";
// Callback to print sessionData
import images4 from "@/assets/GenerateIndex/4.svg";
import images11 from "@/assets/GenerateIndex/11.svg";
import imagesUSDT from "@/assets/GenerateIndex/USDT.png";
import imagesBTC from "@/assets/GenerateIndex/BTC.png";
import imagesBNB from "@/assets/GenerateIndex/BNB.png";
import imagesDOGE from "@/assets/GenerateIndex/DOGE.png";
import imagesETH from "@/assets/GenerateIndex/ETH.png";
import imagesTUSD from "@/assets/GenerateIndex/TUSD.png";
import imagesFDUSD from "@/assets/GenerateIndex/FDUSD.png";
import imagesUSDC from "@/assets/GenerateIndex/USDC.png";
import { InputNumber, Form } from "antd";
import PayPop from "./component/payPop";
const basicColumns = [
  [
    {
      label: "USDT",
      value: "USDT",
      pic: imagesUSDT,
    },
    {
      label: "BTC",
      value: "BTC",
      pic: imagesBTC,
    },
    {
      label: "BNB",
      value: "BNB",
      pic: imagesBNB,
    },
    {
      label: "DOGE",
      value: "DOGE",
      pic: imagesDOGE,
    },
    {
      label: "ETH",
      value: "ETH",
      pic: imagesETH,
    },
    {
      label: "TUSD",
      value: "TUSD",
      pic: imagesTUSD,
    },
    {
      label: "FDUSD",
      value: "FDUSD",
      pic: imagesFDUSD,
    },
    {
      label: "USDC",
      value: "USDC",
      pic: imagesUSDC,
    },
  ],
];

function Home() {
  const PopRef = useRef(null);
  const [form] = Form.useForm();
  const numValue = Form.useWatch("numValue", form);
  const store = useStorage();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(["USDT"]);
  const [principalId, setPrincipalId] = useState("");
  const [currentItem, setCurrentItem] = useState(basicColumns[0][0]);

  const getId = async () => {
    let principalId = store.get("Mindra-generateLogin-principalId");
    setPrincipalId(principalId);
  };
  const findItemWithValue = (dataArray, valueToFind) => {
    return dataArray.filter((item) => item.value === valueToFind);
  };

  useEffect(() => {
    if (value.length > 0) {
      setCurrentItem(findItemWithValue(basicColumns[0], value[0])[0]);
    }
  }, [value]);
  useEffect(() => {
    getId();
  }, []);
  return (
    <PageBg>
      <PayPop ref={PopRef} data={{ numValue, ...currentItem }} />
      <div className={styles.GenerateIndex}>
        <div className={styles.actionContainer}>
          <img
            src={images4}
            alt=""
            className={styles.backIcon}
            onClick={() => {
              console.log("111");
              navigate({ pathname: "/generateIndex" });
            }}
          />
          {/* <img src={images5} alt="" /> */}
        </div>
        <div className={styles.title}>Enter amount</div>
        <div className={styles.desc}></div>
        <div className={styles.formContainer}>
          <div
            className={styles.viewPicker}
            onClick={() => {
              setVisible(true);
            }}>
            <img
              src={currentItem?.pic}
              alt=""
              className={styles.viewPickerPic}
            />
            {currentItem?.label}
            <img src={images11} alt="" className={styles.down} />
          </div>
          <Form form={form}>
            <Form.Item name="numValue">
              <InputNumber
                className={styles.numView}
                defaultValue={0}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Form>
          <Picker
            columns={basicColumns}
            visible={visible}
            renderLabel={(item) => {
              return (
                <div className={styles.pickerItem} style={{ color: "#fff" }}>
                  <img
                    src={item?.pic}
                    alt=""
                    className={styles.viewPickerPic}
                  />
                  {item?.label}
                </div>
              );
            }}
            onClose={() => {
              setVisible(false);
            }}
            value={value}
            onConfirm={(v) => {
              setValue(v);
            }}
          />
        </div>
        <div className={styles.buttonContainer}>
          <div
            className={styles.button}
            onClick={() => {
              console.log("open", PopRef);
              PopRef?.current?.open();
            }}>
            Pay
          </div>
        </div>
      </div>
    </PageBg>
  );
}

export default Home;
