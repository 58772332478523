import React, { useEffect, useState } from "react";
import useStorage from "@/utils/storage";
import PageBg from "./pageBg";
import styles from "./images.module.css";
import { Form, Input } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { aiduser, aiduserPrincipalId } from "@/api/index";
import { SpinLoading } from "antd-mobile";

function Home() {
  const store = useStorage();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState(null);
  const [principalId, setPrincipalId] = useState("");
  const getId = async () => {
    let principalId = store.get("Mindra-generateLogin-principalId");
    setPrincipalId(principalId);
  };

  useEffect(() => {
    getId();
  }, []);
  const onFinish = async (values) => {
    setLoading(true);
    let res = await aiduser({ ...values, principalId });
    if (res.code === 200) {
      setTimeout(async () => {
        let newRes = await aiduserPrincipalId(principalId);
        if (newRes.status === 404) {
          store.set("Mindra-generateLogin-userInfo", null);
        } else {
          store.set("Mindra-generateLogin-userInfo", newRes?.data);
        }
        setLoading(false);
        setImages(newRes?.data?.ai_photo);
        // navigate({ pathname: "/generateUpload" });
      }, 500);
    }
  };
  const validateMessages = {
    required: "${name} is required",
  };
  return (
    <PageBg>
      <div className={styles.GenerateIndex}>
        <div className={styles.title}>Generate images</div>
        <div className={styles.desc}>
          Generate AID external display and use of the LOGO image.
        </div>
        <div className={styles.formContainer}>
          <Form
            form={form}
            requiredMarkStyle="none"
            onFinish={onFinish}
            validateMessages={validateMessages}>
            <Form.Item
              name="nickname"
              label="Nickname"
              disabled={images ? true : false}
              rules={[{ required: true }]}>
              <Input placeholder="Type your nickname" />
            </Form.Item>
            {/* <Form.Item
              name="Wallet address"
              label="Wallet address"
              rules={[{ required: true }]}>
              <Input placeholder="Type your on-chain wallet address" />
            </Form.Item> */}
            <Form.Item
              name="description"
              label="Description"
              disabled={images ? true : false}
              rules={[{ required: true }]}>
              <Input placeholder="Type your favorite food, animals, hobbies, etc. " />
            </Form.Item>
          </Form>
        </div>
        {images && (
          <div>
            <img src={images} alt="" className={styles.avatar} />
          </div>
        )}

        <div className={styles.buttonContainer}>
          {!images ? (
            <div className={styles.button} onClick={() => form.submit()}>
              Generate my AID
              {loading && (
                <div className={styles.connectButtonLoading}>
                  <SpinLoading color="primary" style={{ "--size": "20px" }} />
                </div>
              )}
            </div>
          ) : (
            <div
              className={styles.button}
              onClick={() => {
                navigate({ pathname: "/generateUpload" });
              }}>
              Next
            </div>
          )}
        </div>
      </div>
    </PageBg>
  );
}

export default Home;
