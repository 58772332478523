import React, { useEffect, useState, useRef } from "react";
import {
  compressImage,
  convertToFileBase64,
  removeBase64Prefix,
} from "@/utils/tool.js";
import { faceDetect } from "@/api/index";
import PageBg from "./pageBg";
import styles from "./upload.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { SpinLoading, Toast } from "antd-mobile";

import images4 from "@/assets/GenerateIndex/4.svg";
import images5 from "@/assets/GenerateIndex/5.svg";
function Home() {
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [base64, setBase64] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    let originalSize = file.size;
    console.log("originalSize", originalSize);

    if (originalSize > 1 * 1024 * 1024) {
      let base64 = await compressImage(file);
      console.log("base64", base64);
      setBase64(base64);
    } else {
      let base64 = await convertToFileBase64(file);
      console.log("base64", base64);
      setBase64(base64);
    }
  };

  const handleUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const next = async () => {
    setLoading(true);
    let res = await faceDetect({ image: removeBase64Prefix(base64) });
    console.log("res", res);
    setLoading(false);
    if (res.code === 200) {
      navigate({ pathname: "/generateFaceVerification" });
    } else {
      Toast.show({
        icon: "fail",
        content: res?.data?.msg,
      });
    }
  };
  return (
    <PageBg>
      <div className={styles.GenerateIndex}>
        <div className={styles.actionContainer}>
          <img
            src={images4}
            alt=""
            className={styles.backIcon}
            onClick={() => navigate(-1)}
          />
          <img src={images5} alt="" />
        </div>
        <div className={styles.title}>Upload ID photo</div>
        <div className={styles.desc}>
          Upload ID photo counterfeiting detection, facial recognition
        </div>
        <div className={styles.Upload} onClick={handleUpload}>
          {base64 && <img src={base64} alt="" />}
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
            ref={inputRef}
            accept=".jpg, .jpeg, .png,"
          />
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.button} onClick={next}>
            Face detection
            {loading && (
              <div className={styles.connectButtonLoading}>
                <SpinLoading color="primary" style={{ "--size": "20px" }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </PageBg>
  );
}

export default Home;
