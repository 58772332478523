import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import tab1 from "@/assets/tab/tab1.svg";
import tab1Active from "@/assets/tab/taba1.svg";
import tab2 from "@/assets/tab/tab2.svg";
import tab2Active from "@/assets/tab/taba2.svg";
import tab3 from "@/assets/tab/tab3.svg";
import tab3Active from "@/assets/tab/taba3.svg";
import tab4 from "@/assets/tab/tab4.svg";
import tab4Active from "@/assets/tab/taba4.svg";
import { useNavigate, useLocation } from "react-router-dom";
function TabBar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [tabList, setTabList] = useState([
    {
      images: tab1,
      activeImages: tab1Active,
      name: "Home",
      path: "/homeIndex",
    },
    {
      images: tab2,
      activeImages: tab2Active,
      name: "Tasks",
      path: "/tasks",
    },
    {
      images: tab3,
      activeImages: tab3Active,
      name: "Leaderboard",
      path: "/leaderboard",
    },
    {
      images: tab4,
      activeImages: tab4Active,
      name: "Squad",
      path: "/squad",
    },
  ]);
  const hadleClick = (item) => {
    navigate({ pathname: item?.path });
  };
  return (
    <div className={styles.tabbarContainer}>
      <div className={styles.tabbarContent}>
        {tabList.map((item) => {
          return (
            <div className={styles.tabbarItem} onClick={() => hadleClick(item)}>
              <img
                src={pathname === item?.path ? item.activeImages : item.images}
                alt="tab"
              />
              <img
                src={item.activeImages}
                alt="tab"
                className={styles.absolutActiveImages}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TabBar;
