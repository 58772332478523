import React, { useEffect, useState, useRef } from "react";
import image1 from "@/assets/GenerateIndex/2.svg";
import { faceMatch } from "@/api/index";
import useStorage from "@/utils/storage";
import PageBg from "./pageBg";
import styles from "./faceVerification.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import Webcam from "react-webcam";
import images4 from "@/assets/GenerateIndex/4.svg";
import imagess from "@/assets/GenerateIndex/s.svg";
import imagesf from "@/assets/GenerateIndex/f.svg";
import { removeBase64Prefix } from "@/utils/tool.js";
import { SpinLoading, Toast } from "antd-mobile";
function Home() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const webcamRef = React.useRef(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [base64List, setBase64List] = useState([]);
  const captureNum = useRef(0);
  const store = useStorage();
  const [principalId, setPrincipalId] = useState("");
  const [matchLoading, setMatchLoading] = useState(0);
  const [resultStatus, setResultStatus] = useState(0);
  const getId = async () => {
    let principalId = store.get("Mindra-generateLogin-principalId");
    setPrincipalId(principalId);
    const constraints = { video: true, audio: false };

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          console.log("成功获取到视频流");
          // 使用这个 stream
        })
        .catch(function (err) {
          console.error("无法获取视频输入:", err);
          alert(
            "请确保您已允许网站访问您的摄像头，并且您正在使用支持的浏览器。"
          );
        });
    } else {
      console.error(
        "此浏览器不支持 getUserMedia API 或者没有在安全上下文中运行（如 HTTPS）。"
      );
    }
  };
  // 捕捉图像
  const capture = () => {
    let cNum = captureNum.current + 1;
    captureNum.current = cNum;
    let dataList = [...base64List];
    const imageSrc = webcamRef.current.getScreenshot();
    if (cNum % 2 === 0) {
      dataList[1] = imageSrc;
    } else {
      dataList[0] = imageSrc;
    }
    setBase64List(dataList);
  };
  console.log("base64List", base64List);
  // 开启/关闭摄像头
  const toggleCamera = () => {
    setIsCameraOn(!isCameraOn);
  };

  // 发送图像到后端进行验证
  const verifyFace = async (imageSrc) => {
    setMatchLoading(1);
    setResultStatus(0);
    setLoading(true);
    let res = await faceMatch({
      principal_id: principalId,
      image1: removeBase64Prefix(base64List[0]),
      image2: removeBase64Prefix(base64List[1]),
    });
    setLoading(false);
    setMatchLoading(2);
    if (res.code === 200) {
      setResultStatus(1);
      setTimeout(() => {
        navigate({ pathname: "/generateqrCode" });
      }, 1500);
    } else {
      Toast.show({
        icon: "fail",
        content: res?.data?.msg,
      });
      setResultStatus(2);
    }
  };
  useEffect(() => {
    getId();
  }, []);

  return (
    <PageBg>
      <div className={styles.GenerateIndex}>
        <div className={styles.actionContainer}>
          <img
            src={images4}
            alt=""
            className={styles.backIcon}
            onClick={() => navigate(-1)}
          />
          {/* <img src={images5} alt="" /> */}
        </div>
        <div className={styles.title}>Get ready</div>
        <div className={styles.desc}>
          Get ready for your video selfie.
          <br />
          Please position yourself at the right angle and click the button twice
        </div>
        <div className={styles.Upload}>
          {!isCameraOn && (
            <img src={image1} alt="" className={styles.isCameraImage} />
          )}

          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{ width: 200, height: 200, borderRadius: "50%" }}
            mirrored={true}
            style={{ display: isCameraOn ? "block" : "none" }}
          />
          {matchLoading !== 0 && (
            <div className={styles.mask}>
              {matchLoading === 1 && (
                <SpinLoading color="primary" style={{ "--size": "50px" }} />
              )}
              {resultStatus === 1 && (
                <img src={imagess} alt="" className={styles.maskImage} />
              )}
              {resultStatus === 2 && (
                <img src={imagesf} alt="" className={styles.maskImage} />
              )}
            </div>
          )}
        </div>
        <div className={styles.preview}>
          {base64List.map((item) => {
            return <img src={item} alt="" />;
          })}
        </div>

        <div className={styles.buttonContainer}>
          {!isCameraOn && (
            <div className={styles.button} onClick={toggleCamera}>
              {isCameraOn ? "Turn off camera" : "Turn on camera"}
            </div>
          )}
          {isCameraOn && (
            <div
              className={styles.button}
              onClick={() => {
                captureNum.current = 0;
                setBase64List([]);
                setMatchLoading(0);
                setResultStatus(0);
              }}>
              Retry
            </div>
          )}
          {isCameraOn && base64List.length < 2 && (
            <div className={styles.button} onClick={capture}>
              Capture
            </div>
          )}

          {isCameraOn && base64List.length == 2 && (
            <div
              className={styles.button}
              onClick={verifyFace}
              style={{ marginBottom: 0 }}>
              Match
              {loading && (
                <div className={styles.connectButtonLoading}>
                  <SpinLoading color="primary" style={{ "--size": "20px" }} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </PageBg>
  );
}

export default Home;
