import { awaitTo, apiPrefix } from "@/api/http";

export const userInfo = (id) => {
  return awaitTo(`${apiPrefix}/user/${id}`, { type: "GET" });
};
// 增加获取排行榜接口
export const getUsers = (page, limit) => {
  return awaitTo(`${apiPrefix}/users?page=${page || 1}&limit=${limit || 10}`, {
    type: "GET",
  });
};

export const connectWalletApi = (userId, principalId) => {
  return awaitTo(`${apiPrefix}/connect-wallet`, {
    data: { userId, principalId },
    type: "POST",
  });
};
// 被邀请人进入拿到邀请人的id然后调用接口
export const userInvite = (userId, inviter) => {
  return awaitTo(
    `${apiPrefix}/user/invite?userId=${userId}&inviter=${inviter}`,
    {
      type: "GET",
    }
  );
};
// 完成任务
// "follow_x_ai"        "follow_x_icp"     "join_tg_ai"     "join_tg_icp"
export const doneTasks = (userId, task) => {
  return awaitTo(`${apiPrefix}/tasks`, {
    data: { userId, task },
    type: "POST",
  });
};
// /api/user/ai-photo
export const aiPhoto = (id, limit) => {
  return awaitTo(`${apiPrefix}/user/ai-photo?userId=${id}`, {
    type: "GET",
  });
};

export const aiduserPrincipalId = (principalId) => {
  return awaitTo(`${apiPrefix}/aiduser/${principalId}`, {
    type: "GET",
  });
};
export const aiduser = (data) => {
  return awaitTo(`${apiPrefix}/aiduser`, {
    type: "POST",
    data,
  });
};
export const faceDetect = (data) => {
  return awaitTo(`${apiPrefix}/face-detect`, {
    type: "POST",
    data,
  });
};
export const faceMatch = (data) => {
  return awaitTo(`${apiPrefix}/face-match`, {
    type: "POST",
    data,
  });
};
export const qrScan = (data) => {
  return awaitTo(`${apiPrefix}/qr-scan`, {
    type: "POST",
    data,
  });
};

// //multipart/form-data
// //公共请求头配置，本项目请求头大多数接口是这个，所以这里可以配置一下，对于特殊接口单独配置
// export const videoSnapshot = (data) => {
//   return awaitTo('/auth/register/face-scan/video-snapshot', {
//     data: data, type: 'POST', headers: {
//       "Content-Type": "multipart/form-data"
//     }
//   })
// }

// export const faceScanVideo = (data) => {
//   return awaitTo('/auth/register/face-scan/video', {
//     data: data, type: 'POST', headers: {
//       "Content-Type": "multipart/form-data"
//     }
//   });
// }
