import React, { useState, useEffect, useRef } from "react";
import { HttpAgent, Actor } from "@dfinity/agent";
import { AuthClient } from "@dfinity/auth-client";
import { idlFactory } from "./backend_canister.did.js"; // Import the Candid interface
import { SpinLoading } from "antd-mobile";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { aiPhoto, doneTasks } from "@/api/index";
import { getQueryParams, isDesktopBrowser } from "@/utils/tool.js";
import bg1 from "@/assets/2.svg";
import image0 from "@/assets/AiAvatar/0.svg";
import image1 from "@/assets/AiAvatar/1.svg";
import image2 from "@/assets/AiAvatar/2.svg";
import image3 from "@/assets/AiAvatar/3.svg";
import image5 from "@/assets/AiAvatar/5.svg";
import styles from "./index.module.css";
import classNames from "classnames";
const canisterId = "er6gs-wiaaa-aaaan-qmvea-cai";
const host = "https://a4gq6-oaaaa-aaaab-qaa4q-cai.raw.icp0.io";

export default function App() {
  const [isPc, setIsPc] = useState(isDesktopBrowser());
  const [authClient, setAuthClient] = useState(null);
  const [agent, setAgent] = useState(null);
  const [avatar, setAvatar] = useState("");
  const [loading, setLoading] = useState(false);
  const [mintLoading, setMintLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [mintError, setMintError] = useState(null);
  const [isDone, setIsDone] = useState(false);
  const [currentTask, setCurrentTask] = useState(1);
  const urlQuery = useRef(getQueryParams(window.location.href));
  const [showPop, setShowPop] = useState(false);
  const initAuthClient = async () => {
    setLoading(true);
    setLoginError(null);
    try {
      const authClient = await AuthClient.create();
      setAuthClient(authClient);
      // authClient.getIdentity().getPrincipal().toString
      console.log("---", authClient.getIdentity().getPrincipal());
      await authClient.login({
        maxTimeToLive: BigInt(7 * 24 * 60 * 60 * 1000 * 1000 * 1000),
        onSuccess: () => initializeAgent(authClient),
        onError: (error) => {
          setLoginError(error);
          setLoading(false);
        },
      });
    } catch (e) {
      setLoginError(e);
      setLoading(false);
    }
  };

  const initializeAgent = async (authClient) => {
    const identity = await authClient.getIdentity();
    const agent = new HttpAgent({ identity, host });
    setAgent(agent);

    try {
      const res = await aiPhoto(urlQuery?.current?.userId);
      const avatarUrl = res?.data?.ai_photo;

      if (!avatarUrl) {
        throw new Error("Failed to generate AI avatar.");
      }

      setAvatar(avatarUrl);
      setCurrentTask(3); // Move to the next task after successful AI avatar generation
    } catch (error) {
      setLoginError(error);
      setLoading(false);
      return;
    }

    setLoading(false);
  };

  const mintNFT = async () => {
    if (!agent || !avatar) {
      return;
    }

    setMintLoading(true);
    setMintError(null);

    try {
      const nftActor = Actor.createActor(idlFactory, { agent, canisterId });
      const userPrincipal = await agent.getPrincipal();
      var userId = urlQuery?.current?.userId;

      console.log(userPrincipal.toString());

      // const avatarBase64 = await fetch(avatar)
      // .then((response) => response.blob())
      // .then((blob) => {
      //   return new Promise((resolve) => {
      //     const reader = new FileReader();
      //     reader.onloadend = () => resolve(reader.result.split(',')[1]);
      //     reader.readAsDataURL(blob);
      //   });
      // });

      const avatarBase64 = await fetch(avatar)
        .then((response) => response.blob())
        .then((blob) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(",")[1]); // 提取Base64数据
            reader.readAsDataURL(blob);
          });
        });

      // 将Base64解码为字节数组
      const byteCharacters = atob(avatarBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const metadata = [
        {
          data: byteArray.slice(0, 10000),
          key_val_data: [
            { key: "name", val: { TextContent: "Mindra AI Avatar NFT" } },
            {
              key: "description",
              val: {
                TextContent:
                  "Mindra AI Avatar NFT for Telegram User: " + userId,
              },
            },
          ],
          purpose: { Rendered: null },
        },
      ];
      const result = await nftActor.mintDip721(userPrincipal, metadata);
      console.log("result", result);
      if (result?.Ok) {
        setShowPop(true);
        await doneTasks(urlQuery?.current?.userId, "mint_nft");
        setIsDone(true);
        setCurrentTask(4);
      } else {
        setMintError(result?.Err || "Minting failed");
      }
    } catch (error) {
      setMintError(error.message || "An error occurred during minting.");
    }

    setMintLoading(false);
  };

  useEffect(() => {
    if (currentTask === 1 && !loading && !isDone) {
      initAuthClient();
    }
    return () => {
      setLoading(false);
    };
  }, [currentTask]);

  return (
    <div className={styles.AiAvatar}>
      {showPop && <div className={styles.mask}></div>}
      {showPop && (
        <div className={styles.successContainer}>
          <img src={image5} alt="" />
          Mint successfully
          <div
            className={styles.sbutton}
            onClick={() => {
              setShowPop(false);
            }}>
            OK
          </div>
        </div>
      )}
      {isPc ? (
        <div className={styles.pcContainer}>
          <div className={styles.headerContainer}>
            <img src={bg1} alt="bg1" className={styles.bg1} />
            Welcome to Mindra.AI on ICP
          </div>
          <div className={styles.taskContainer}>
            <div
              className={classNames({
                [styles.taskItem]: true,
                [styles.hoverTaskItem]: currentTask === 1,
                [styles.disableTaskItem]: currentTask !== 1,
              })}
              onClick={() => {
                if (currentTask === 1) {
                  initAuthClient();
                }
              }}>
              <div className={styles.taskItemContent}>
                <div className={styles.taskItemLeft}>
                  <img
                    src={image1}
                    alt=""
                    className={styles.taskItemLeftIcon}
                  />{" "}
                  Connect Wallet
                </div>
                {currentTask > 1 && <img src={image0} alt="" />}
              </div>

              {loading && (
                <div className={styles.connectButtonLoading}>
                  <SpinLoading style={{ "--size": "40px" }} color="#fff" />
                </div>
              )}
            </div>
            <div
              className={classNames({
                [styles.taskItem]: true,
                [styles.hoverTaskItem]: currentTask === 2,
                [styles.disableTaskItem]: currentTask !== 2,
              })}>
              <div className={styles.taskItemContent}>
                <div className={styles.taskItemLeft}>
                  <img
                    src={image2}
                    alt=""
                    className={styles.taskItemLeftIcon}
                  />{" "}
                  Generate AI Avatar
                </div>
                {currentTask > 2 && <img src={image0} alt="" />}
              </div>
              {avatar && <img src={avatar} className={styles.avatar} alt="" />}
              {mintLoading && (
                <div className={styles.connectButtonLoading}>
                  <SpinLoading style={{ "--size": "40px" }} color="#fff" />
                </div>
              )}
            </div>
            <div
              className={classNames({
                [styles.taskItem]: true,
                [styles.hoverTaskItem]: currentTask === 3,
                [styles.disableTaskItem]: currentTask !== 3,
              })}>
              <div className={styles.taskItemLeft} onClick={mintNFT}>
                <div className={styles.taskItemContent}>
                  <div className={styles.taskItemLeft}>
                    <img
                      src={image3}
                      alt=""
                      className={styles.taskItemLeftIcon}
                    />{" "}
                    Mint NFT
                  </div>
                  {currentTask > 3 && <img src={image0} alt="" />}
                </div>
              </div>
            </div>
            {loginError && (
              <div className={styles.errorMsg}>
                {loginError.message || "An error occurred. Please try again."}
              </div>
            )}
            {mintError && <div className={styles.errorMsg}>{mintError}</div>}
          </div>
        </div>
      ) : (
        <div className={styles.tooltipsPcContainer}>
          <div className={styles.tooltipsPc}>Please open on your PC</div>
          <ExclamationCircleOutlined
            style={{ fontSize: "15vw", color: "#08c" }}
          />
        </div>
      )}
    </div>
  );
}
