import React, { useImperativeHandle, forwardRef, useState } from "react";
import styles from "./index.module.css";
import { Popup, Space, Button } from "antd-mobile";
import { useNavigate, useLocation } from "react-router-dom";
import image1 from "@/assets/3.svg";
import rank1 from "@/assets/leaderboard/1.svg";
import rank2 from "@/assets/leaderboard/2.svg";
import rank3 from "@/assets/leaderboard/3.svg";
const LeaderboardItem = (props, ref) => {
  const { showBordered=false, rank, name='-', avatar=image1, coin='0' } = props;
  useImperativeHandle(ref, () => ({}));
  const renderRake = () => {
    if (rank === 1) {
      return <img src={rank1} alt="rank" className={styles.rankIcon} />;
    } else if (rank === 2) {
      return <img src={rank2} alt="rank" className={styles.rankIcon} />;
    } else if (rank === 3) {
      return <img src={rank3} alt="rank" className={styles.rankIcon} />;
    } else {
      return <div className={styles.rankNum}>#{rank}</div>;
    }
  };
  return (
    <div
      className={styles.LeaderboardItem}
      style={{
        borderColor: showBordered ? "#FFF" : "rgba(26, 26, 26, 1)",
      }}>
      <img src={avatar||image1} alt="logo" className={styles.avatar} />
      <div className={styles.content}>
        <div className={styles.name}>{name||'-'}</div>
        <div className={styles.coin}>{coin||0} POINTS</div>
      </div>
      {renderRake()}
    </div>
  );
};

export default forwardRef(LeaderboardItem);
