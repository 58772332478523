import axios from "axios";

const instance = axios.create({
  timeout: 30 * 1000,
  withCredentials: true, // 允许跨域设置cookies
});

export function awaitTo(url, options) {
  return http(url, options)
    .then((res: any) => res)
    .catch((err: any) => err);
}
export const apiPrefix = "/api";
export default function http(url, options) {
  const {
    data = {},
    params = {},
    headers = {},
    dataType = "json",
    type = "GET",
  } = options;

  if (!url) {
    console.error("接口地址不能为空");
    return new Promise((resolve, reject) => {
      reject(null);
    });
  }
  let token = sessionStorage.getItem("access_token");
  // console.log('token', token)
  let auth = token && token.length > 0 ? `Bearer ${token}` : "";
  // console.log('auth', auth)
  // 设置公共参数
  instance.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: auth,
    ...headers,
  };
  return new Promise((resolve, reject) => {
    console.log('data',data)
    console.log('dataType',dataType)
    instance
      .request({
        method: type,
        url: url,
        params: params,
        data: data,
        responseType: dataType,
        headers, // 设置默认请求头
      })
      .then((res) => {
        if ([200, 201].includes(res.status) && res.data) {
          resolve(res.data);
        }
        return reject(res.statusText || "");
      })
      .catch((error) => {
        console.error(url, error);
        if (error.response.status == 401) {
          window.location.href = window.location.origin;
        }
        if (!error.response && error.message.indexOf("timeout of ") > -1) {
          return reject("ERROR_NETWORK_TIMEOUT");
        }
        return reject(error.response);
      });
  });
}
