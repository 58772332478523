import React, { useEffect, useState, useRef } from "react";
import TabBar from "@/components/TabBar";
import styles from "./index.module.css";
import image1 from "@/assets/3.svg";
import InvitePop from "@/components/InvitePop";
import useStorage from "@/utils/storage";
function Squad() {
  const store = useStorage();
  const PopRef = useRef(null);
  const [userInfoData, setUserInfoData] = useState(
    store.get("Mindra-Userinfo")
  );

  return (
    <div className={styles.SquadContainer}>
      <div className={styles.title}>INVITE PEOPLE TO YOUR SQUAD</div>
      <img src={image1} alt="logo" className={styles.logo} />
      <button
        className={styles.inviteButton}
        onClick={() => {
          PopRef?.current?.open();
        }}>
        Invite friend
      </button>
      <div className={styles.invitesNum}>
        {userInfoData?.invitees?.length} invites
      </div>
      <InvitePop ref={PopRef} />
      <TabBar />
    </div>
  );
}

export default Squad;
