import React, { useEffect, useState } from "react";
import image1 from "@/assets/GenerateIndex/2.svg";
import useStorage from "@/utils/storage";
import PageBg from "./pageBg";
import styles from "./index.module.css";
import { Avatar } from "antd-mobile";
import { formatStringWithEllipsis } from "@/utils/tool";
import { useNavigate } from "react-router-dom";
import { aiduserPrincipalId } from "@/api/index";
function Home() {
  const store = useStorage();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const goKyc = () => {
    navigate({ pathname: "/generateImages" });
  };
  const getId = async () => {
    let principalId = store.get("Mindra-generateLogin-principalId");
    let res = await aiduserPrincipalId(principalId);
    console.log("res", res);
    if (res.status === 404) {
      store.set("Mindra-generateLogin-userInfo", null);
      setUserInfo(null);
      goKyc();
    } else {
      store.set("Mindra-generateLogin-userInfo", res?.data);
      setUserInfo(res?.data);
    }
  };
  useEffect(() => {
    getId();
  }, []);
  return (
    <PageBg indexPage={true}>
      <div className={styles.GenerateIndex}>
        <div className={styles.Avatar}>
          <Avatar
            src={userInfo?.ai_photo || ""}
            style={{ "--size": "26.6vw", "--border-radius": "50%" }}
            fallback={
              <img
                src={image1}
                alt=""
                style={{ objectFit: "cover", width: "100%" }}
              />
            }
          />
        </div>
        <div className={styles.nameContainer}>
          <div className={styles.name}>{userInfo?.nickname || "NO NAME"}</div>
          <div className={styles.hash}>
            {formatStringWithEllipsis(userInfo?.principal_id, 6, 6)}
          </div>
        </div>
        <div className={styles.infoItem}>Lindsey@gmail.com</div>
        <div className={styles.infoItem}>
          Thanks for your hark work!Thanks for your hark work!Thanks for your
          hark work!
        </div>
        <div className={styles.infoItem}>显示信息...</div>
        <div className={styles.infoItem}>显示信息...</div>
        <div className={styles.buttonContainer}>
          {!userInfo && (
            <div className={styles.button} onClick={goKyc}>
              kyc
            </div>
          )}
          {userInfo && (
            <div
              className={styles.button}
              onClick={() => navigate({ pathname: "/generatePay" })}>
              pay
            </div>
          )}
        </div>
      </div>
    </PageBg>
  );
}

export default Home;
